/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import axios from 'axios';
import { Power0, TweenMax } from 'gsap';
import $ from 'jquery';
import Parsley from 'parsleyjs';
import './index.scss';

var imagesLoaded = require('imagesloaded');

// eslint-disable-next-line no-unused-vars
let CheckAgeComponent = (function() {

  // global vars
  let $container = $('.age-check'),
      $inputYear = $('.age-check input.input-birthYear'),
      $inputMonth = $('.age-check input.input-birthMonth'),
      $inputDay = $('.age-check input.input-birthDay')

  // functions
  let setCookie = function (cname, cvalue, exdays) {
      let d = new Date(),
          url = "domain="+ window.location.hostname +";path=/",
          expires
      //
      d.setTime(d.getTime() + (exdays*24*60*60*1000))
      expires = "expires="+d.toUTCString()

      document.cookie = cname + "=" + cvalue + ";" + url + ";" + expires
  }

  let checkUserAge = function() {
      var notAgeConfirmCookie = document.cookie.indexOf("age_confirm") == -1;

      if (!notAgeConfirmCookie) {
          $container.css('display', 'none')
      }
  }

  let validateDDMMYYYY = function() {
      if ($inputDay.val().length < 2) {
          $inputDay.addClass('error')
      } else {
          $inputDay.removeClass('error')
      }

      if ($inputMonth.val().length < 2) {
          $inputMonth.addClass('error')
      } else {
          $inputMonth.removeClass('error')
      }

      if ($inputYear.val().length < 4) {
          $inputYear.addClass('error')
      } else {
          $inputYear.removeClass('error')
      }
  }
  
  let setEvents = function() {
      let $formCheckAge = $('#formCheckAge'),
          $ageCheckInput = $(".age-check__input"),
          $btnCheckAge = $('.btn--age-check')
      
      $formCheckAge.on('submit', function(event) {
          event.preventDefault()
      })
      
      $ageCheckInput.keyup(function (e) {
          if ($(this).val().length >= 2) {
              $(this).parents('.input-group').next().find('input').focus()
          }
      });

      $ageCheckInput.keydown(function (e) {
          let $this = $(this),
              isClickedCtrlA = e.keyCode == 65 && e.ctrlKey === true,
              isClickedCtrlC = e.keyCode == 67 && e.ctrlKey === true,
              isClickedCtrlX = e.keyCode == 88 && e.ctrlKey === true,
              // backspace, delete, tab, escape, enter and .
              isClickedFuncKeys = $.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1,
              // Allow: home, end, left, right
              isClickedNaviKeys = e.keyCode >= 35 && e.keyCode <= 39,
              //
              isShift = e.shiftKey,
              isTypingDayOrMonth = ($this.attr('name') == 'birthDay' || $this.attr('name') == 'birthMonth')

          if (isClickedFuncKeys || (isClickedCtrlA) || isClickedCtrlC || isClickedCtrlX || 
              isClickedNaviKeys) {
              // let it happen, don't do anything
              return
          }

          // Ensure that it is a number and stop the keypress
          if ((isShift || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
              e.preventDefault()
          }

          if (isTypingDayOrMonth) {
              if ($this.val().length >= 2) {
                  e.preventDefault();
              }
          }

          if ($this.val().length >= 4) {
              e.preventDefault()
          }
      })

      $btnCheckAge.click(function(e) {
          e.preventDefault();

          let _userBirthday = $inputYear.val() + '-' + $inputMonth.val() + '-' + $inputDay.val(),
              userBirthday = new Date(_userBirthday),
              currentDate = new Date(),
              timeDiff = Math.abs(currentDate.getTime() - userBirthday.getTime()),
              yearDiff = Math.ceil(timeDiff / (1000 * 3600 * 24 * 365)),
              redirectWiseDrinkingStatement = $inputYear.val().length == 4 && $inputMonth.val().length == 2 && $inputDay.val().length == 2

          validateDDMMYYYY()

          if (yearDiff > 18) {
              // preAnimationStage()

              // $container.fadeOut(function() {
              //     animateStage()
              // })

              $('html, body').animate({
                  scrollTop: 0
              }, 20)

              setCookie('age_confirm', 'true', 30)
              window.location.reload()
          } else if (redirectWiseDrinkingStatement) {
              window.location = 'https://www.wise-drinking.com/';
          }
      })
  }
  
  let init = function() {
      checkUserAge()
      setEvents()
  }

  init()

  $(document).ready(function() {
      init()
  })
})()

// eslint-disable-next-line no-unused-vars
let AppComponent = (function() {
  let APP_H = window.innerHeight,
      APP_W = window.innerWidth,
      scrollTop = $(window).scrollTop(),
      // preloading,
      $body = $('body'),
      imagesSize = 0,
      imagesLoadedCount = 0,
      $percentOutput = $('.lp-progress-percent'),
      $progressBar = $('.lp-progress-bar span')

  let setupForm = function() {
      Parsley.addMessages('pl', {
              defaultMessage: "Wartość wygląda na nieprawidłową",
              type: {
                  email:        "Wpisz poprawny adres e-mail",
                  url:          "Wpisz poprawny adres URL",
                  number:       "Wpisz poprawną liczbę",
                  integer:      "Dozwolone są jedynie liczby całkowite",
                  digits:       "Dozwolone są jedynie cyfry",
                  alphanum:     "Dozwolone są jedynie znaki alfanumeryczne"
              },
              notblank:       "Pole nie może być puste",
              required:       "Pole jest wymagane",
              pattern:        "Pole zawiera nieprawidłową wartość. Wpisz numer telefonu w formacie 9-cyfrowym, np. 123123123",
              min:            "Wartość nie może być mniejsza od %s",
              max:            "Wartość nie może być większa od %s",
              range:          "Wartość powinna zaweriać się pomiędzy %s a %s",
          // minlength:      "Minimalna ilość znaków wynosi %s",
          minlength:      "Wpisz telefon w formacie 123-456-789",
          maxlength:      "Maksymalna ilość znaków wynosi %s",
          length:         "Ilość znaków wynosi od %s do %s",
          mincheck:       "Wybierz minimalnie %s opcji",
          maxcheck:       "Wybierz maksymalnie %s opcji",
          check:          "Wybierz od %s do %s opcji",
          equalto:        "Wartości nie są identyczne"
      })

      Parsley.setLocale('pl')
  }

  let submitForm = async function($form) {
      buildDateOfBirth();
      
      let $this = $form,
            obj = new FormData($this.get(0)),
          data = $this.serialize(),
          id = $this.attr('id'),
          url = $this.attr('action')

      url = url.replace('submit_', '')

      let dataToSend = {
        entryType: 'consumer',
        collectorCreatedDate: new Date(),
        contactType: 'B2C',
        activities: [
          {
            activityId: '<ACTIVITY_ID>',
            activityName: '<ACTIVITY_NAME>',
            activityType: '<ACTIVITY_TYPE>',
            activityStartDate: '',
            activityEndDate: '',
            activityDescription: '',
            eventDate: '',
            url: '',
            enquiryTopic: '',
            enquiryMessage: '',
            preferredVisitDate: '',
            preferredVisitTime: '',
            visitMessage: '',
            visitCategory: '',
            visitLanguage: '',
            visitorNumberChildren: '',
            visitorNumber: '',
            visitType: '',
            tourSelection: ''
          }
        ],
        firstname: obj.get('first_name'),
        lastname: obj.get('first_name'),
        birthday: obj.get('date_of_birth'),
        email: obj.get('email'),
        address: [
          {
            addressType: 'main',
            countryCode: 'PL'
          }
        ],
        phone: [obj.get('phone')],
        optIns: [
          {
            brand: 'Wyborowa',
            optInStatus: true,
            legalConsent: 'Oświadczam, że jestem osobą pełnoletnią'
          },
          {
            brand: 'Wyborowa',
            optInStatus: true,
            legalConsent: 'Oświadczam, że zapoznałem się z regulaminem i akceptuję jego treść'
          },
          {
            brand: 'Wyborowa',
            optInStatus: true,
            legalConsent: 'Wyrażam zgodę na przesyłanie przez Wyborowa S.A.'
          }
        ],
        preferences: [],
        personalInterests: [],
        comChannelPref: [],
        comTypePref: [],
        source: [
          {
            prEntity: 'POLAND',
            touchPointName: '<TOUCHPOINT_NAME>',
            touchPointType: 'Webform',
            touchPointDescription: '',
            system: {
              type: 'Direct API'
            }
          }
        ],
        languageCode: 'pl'
      }

      console.log(dataToSend)

      await axios.post(url ? url : '/', dataToSend, {
        headers: {
            "accept": "application/json",
            "Access-Control-Allow-Origin":"*",
            api_key: 'z8xxg3m7pfthc4q7h9vv76j7'
        }
      })

    //   $.ajax({
    //       url: url ? url : '/',
    //       type : 'POST',
    //       cache : false,
    //       data : JSON.stringify(dataToSend),
    //       contentType:"application/json; charset=utf-8",
    //     dataType:"json",
    //       crossDomain: true,
    //       xhrFields: {
    //         withCredentials: true
    //     },
    //       headers: {
    //         "accept": "application/json",
    //         "Access-Control-Allow-Origin":"*",
    //         "api_key": 'z8xxg3m7pfthc4q7h9vv76j7'
    //       },
    //       beforeSend: function() {
    //           $this.find('.btn').html('wysyłanie...')
    //           $this.find('.btn').attr('disabled', 'disabled')
    //           $this.parent().find('.section__formMessage').addClass('hidden')
    //           $this.parent().find('.section__formMessage .success').addClass('hidden')
    //           $this.parent().find('.section__formMessage .error').addClass('hidden')
    //       },
    //       success: function(_res) {
    //           let res = JSON.parse(_res)

    //           if (res.status) { // saved
    //               // old ajax 
    //               $this.get(0).reset()

    //               $this.fadeOut(function() {
    //                   $this.parent().find('.section__formMessage').removeClass('hidden')
    //                   $this.parent().find('.section__formMessage .success').removeClass('hidden')

    //                   $('html, body').animate({
    //                       scrollTop: $('.section__formMessage').offset().top - $('.header').height()
    //                   }, 300)
    //               })
    //           } else {
    //               $this.parent().find('.section__formMessage').removeClass('hidden')
    //               $this.parent().find('.section__formMessage .error').removeClass('hidden')
    //               $this.find('.btn').attr('disabled', false)
    //               $this.find('.btn').html('wyślij')
    //           }
    //       }, 
    //       error: function(res) {
    //           $this.find('.btn').html('wyślij')
    //           $this.find('.btn').attr('disabled', false)
    //           $this.parent().find('.section__formMessage').removeClass('hidden')
    //           $this.parent().find('.section__formMessage .error').removeClass('hidden')
    //       },
    //   })
  }

  let showHideNav = function() {
      let $navTrigger = $('.header__navTrigger'),
          $nav = $('.header__nav'),
          isNavActive = $nav.hasClass('active')

      if (isNavActive && APP_W <= 768) {
          $navTrigger.removeClass('active')
          $nav.removeClass('active')
      } else {
          $navTrigger.addClass('active')
          $nav.addClass('active')
      }
  }

  let setupEvents = function() {
      $(window).on('resize', function(event) {
          APP_H = window.innerHeight;
          APP_W = window.innerWidth;
      });

      $(window).on('scroll', function(event) {
          scrollTop = $(window).scrollTop()
      });

      $('#answer').on('keyup', function() {
            let $this = $(this)
            $this.val( $this.val().substring(0, 500) )
            let thisId = $this.attr('id')
            let $label = $(`label[for="${thisId}"]`)
            let template = $this.data('template')
            let limit = $this.val().length
            let newLabel = template.replace('CURRENT', limit)
            $label.html(newLabel)
      })

      $('form').on('submit', function(event) {
          event.preventDefault()
          let _id = '#' + $(this).attr('id');
          let formParsley = $(_id).parsley();

          formParsley.destroy()

          if (formParsley.validate()) {
              submitForm( $(_id) );
          }
      })

      $('[data-bypass]').on('click', function(event) {
          event.preventDefault();
          window.open($(this).attr('href'))
      })
      
      $('.header__navTrigger').on('click', function(event) {
          event.preventDefault()

          showHideNav()
      })

      $('.header__nav__link').on('click', function(event) {
          if (!$(this).data('bypass')) {
              event.preventDefault()
          }

          showHideNav()
      })

      $('.section__winnersSwitcher button').on('click', function(event) {
          event.preventDefault()
          //
          let $this = $(this)

          if (!$this.parent().hasClass('disabled')) {
              let divToShow = '#' + $this.data('show')
              //
              $('.section__winnersSwitcher li').removeClass('active')
              $this.parent().addClass('active')
              //
              $('.contestResults').removeClass('active')
              $(divToShow).addClass('active')

              if (APP_W < 768) {
                  $('html, body').animate({
                      scrollTop: $(divToShow).offset().top - $('.header').height() - 40
                  }, 300)
              }
          }
      })
  }

  let enableSmoothScroll = function() {
      $('a[href*="#"]').not('[href="#"]').not('[href="#0"]').not('.open-popup').click(function(event) {
          if (
            window.location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
            && window.location.hostname == this.hostname
          ) {
              let target = $(this.hash);
              let hash = this.hash;
              target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

              if (target.length) {
                  event.preventDefault();

                  let offsetTop = target.offset().top - $('.header').outerHeight();

                  $('html, body').animate({
                      scrollTop: offsetTop
                  }, 500, function() {
                      if (hash == '#top') {
                          $('#formularz-konkursowy').find('input').first().focus()
                      }
                  })
              }
          }
      });
  }

  let buildDateOfBirth = function() {
      let formatted_year = $('#form__birthDay').val() + '-' + $('#form__birthMonth').val() + '-' + $('#form__birthYear').val()
      $('#date_of_birth').get(0).value = formatted_year
  }

  let preAnimationStage = function() {
      TweenMax.set( [$('.page'), $('.footer')], {
          opacity: 0
      })
      
      TweenMax.set( [$('.section__kvStage'), $('.section__kvInfoOuter'),  $('.section__partner'), $('.section__prizes')], {
          opacity: 0
      })
  
      TweenMax.set( $('.section__kvSlogan'), {
          scale: 1.2
      })
      
      TweenMax.set( $('.section__kvSloganBg'), {
          width: '0%'
      })
  
      TweenMax.set( $('.section__kvBottle'), {
          right: APP_W > 1550 ? '-5%' : '6%',
          opacity: 0
      })
  }
  
  let animateStage = function() {
      TweenMax.to( $('.page'), 0.4, {
          opacity: 1,
          delay: 0
      })
  
      TweenMax.to( $('.section__kvStage'), 0.4, {
          opacity: 1,
          delay: 0.3,
      })
  
      TweenMax.to( [$('.section__kvInfoOuter')], 0.4, {
          opacity: 1,
          delay: 0.6
      })

      TweenMax.to( [$('.section__partner'), $('.section__prizes') ], 0.4, {
          opacity: 1,
          delay: 0.9
      })

      TweenMax.to( [$('.footer')], 0.4, {
          opacity: 1,
          delay: 1.2
      })  
  
      TweenMax.to( $('.section__kvBottle'), 0.6, {
          right: APP_W > 1550 ? '2%' : '6%',
          delay: 0.3,
          opacity: 1,
          ease: Power0.ease
      })
  }

  var onLoadDone = function() {
      $body.find('.preloading').each(function(index, el) {
          $(this).remove()
      })

      preAnimationStage()

      TweenMax.to($('.lp-preload'), 0.1, {
          opacity: 0,
          ease: Power0.easeOut,
          onComplete: function() {
              setTimeout(function() {
                  animateStage()
                  $body.removeClass('loading')
              }, 200)
          }
      })  
  }

  var onProgress = function(instance, image) {
      let result = image.isLoaded ? 'loaded' : 'broken'
      // 
      imagesLoadedCount = imagesLoadedCount + 1

      let percent = (100 * (imagesLoadedCount / imagesSize)).toFixed(0)

      $percentOutput.html(percent + '%')
      $progressBar.css('width', percent + '%')
  }
  
  let preload = function(images) {
      var _this = this,
          imgLoad

      if (images) {
          $.each(images, function(index, val) {
              $('body').find('.footer').append('<img class="preloading" src=" ' + val +' ">')
          })
      }

      imgLoad = imagesLoaded('body', { background: true });
      imagesSize = imgLoad.images.length

      imgLoad.on('done', onLoadDone)
      imgLoad.on('fail', onLoadDone)
      imgLoad.on('progress', onProgress)
  }

  let init = function() {

      if (window.location.hash === '#konkurs') {
          $('.visible-konkurs').removeClass('visible-konkurs')
          $('.hidden-konkurs').remove()
      }
      
      setupEvents()
      setupForm()
      $('#formularz-konkursowy').parsley()
      enableSmoothScroll()
      preload()
  }

  $(document).ready(function($) {
      init()
  })

})();

let UploadFile = (function () {

    var $uploadGroup = $('.form__uploadGroup')
    // var $inputUpload = $('.form__rowUpload__input');
  
    let makeid = function () {
      var text = '';
      var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  
      for (var i = 0; i < 5; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
  
      return text;
    }
  
    let setPathToUploadedFile = function ($uploadGroup, url) {
      var imagePath = window.location.origin + '/' + url;
  
      // $('.form__rowUpload__url').attr('value', imagePath);
      var item = $uploadGroup.find('.form__rowUpload__url').get(0);
      item.value = imagePath;
  
      $uploadGroup.find('.form__imagePreview').removeClass('hidden')
      $uploadGroup.find('.form__imagePreview__output').html('<img src="'+url+'" />')
      $uploadGroup.find('.form__rowUpload__button span').text('ZMIEŃ OBRAZEK');
      $uploadGroup.find('.form__rowUpload__urlPreloader').addClass('hidden');
    }
  
    let viewMessage = function ($uploadGroup, data) {
      // var status = data.status;
      var message = data.message;
  
      var text = '';
  
      var $output = $uploadGroup.find('.form__uploadMessage__inner');
  
      switch (message) {
        case 'uszkodzony':
          text = 'Plik uszkodzony. Prosimy wybrać inny.'
          break;
        case 'duplikat':
          text = 'Wystąpił błąd.'
          break;
  
        case 'large_file':
          text = 'Zbyt duży rozmiar pliku. Prosimy wybrać inny.'
          break;
  
        default:
          text = 'Wystąpił nieoczekiwany błąd. Prosimy wybrać inny plik.'
          break;
      }
  
      $uploadGroup.find('.form__rowUpload__urlPreloader').addClass('hidden');
      $uploadGroup.find('.form__uploadMessage').removeClass('hidden')
      $output.html('<p class="error mt-0">'+text+'</p>')
    }
  
    let uploadFile = function ($uploadGroup, file) {
      // var fd = new FormData(document.getElementById('formularz-konkursowy'));
  
      var fd = new FormData();
  
      let uploadUrl = $uploadGroup.data('uploadto')
      uploadUrl = uploadUrl.replace('submit_', '')
  
      fd.append('label', makeid() + '-');
      fd.append('file', file);
  
      $.ajax({
        url: uploadUrl,
        type: 'POST',
        data: fd,
        processData: false,
        contentType: false,
        beforeSend: function () {
          $uploadGroup.find('.form__uploadMessage').addClass('hidden')
          $uploadGroup.find('.form__rowUpload__urlPreloader').removeClass('hidden')
          $uploadGroup.find('.form__imagePreview').addClass('hidden')
          $uploadGroup.find('.form__rowUpload__urlOuter .parsley-errors-list').remove();
        },
      }).done(function (_data) {
        var data = JSON.parse(_data);
  
        if (data.status == 'success') {
          setPathToUploadedFile($uploadGroup, data.path)
          $('.form__rowUpload__textBtnError').addClass('hidden')
        } else {
          viewMessage($uploadGroup, data);
          $('.form__rowUpload__textBtnError').removeClass('hidden')
        }
  
      });
    }
  
    let setEvents = function () {
  
      $uploadGroup.each(function() {
  
        let $this = $(this)
        let $inputUpload = $this.find('.form__rowUpload__input')
        
        $inputUpload.on('change', function (event) {
          event.preventDefault();
  
          let fileToUpload = $(this).get(0).files[0];
  
          uploadFile($this, fileToUpload);
        });
      })
  
      $('.textarea-count').on('keyup', function() {
        let $this = $(this),
          length = $this.val().length,
          $output = $($this.data('countouter')),
          max = $this.data('parsley-maxlength');
  
          $output.html(length)
  
          if (length > max) {
            $output.parent().addClass('error')
          } else {
            $output.parent().removeClass('error')
          }
      })
    
    }
  
    let init = function () {
      setEvents()
  
      if ($('.template-contest').length > 0) {
        $('.header-info').addClass('hidden')
  
        $('.template-contest .slider__slide__inner').append('<a href="/app/themes/bonnemaman/dist/images/pdf/regulamin.pdf" data-bypass target="_blank" class="d-block mt-3 text-small" style="color: #000; text-decoration: underline;">Regulamin</a>')
      }
    }
  
    init()
})();
